import banner from '../assets/ServatomBanner.jpg';
import '../index.css';

const Footer = () => {
    return (
        <div className='footer'>
            <img src={banner} />
        </div>
    )
}

export default Footer;